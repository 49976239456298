import * as React from "react";

function PlayButton(props) {
  const { width, height } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 60 60"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>{"ic_play_md"}</title>
      <g fill="none" fillRule="evenodd">
        <path
          d="M30 .833C13.9.833.833 13.9.833 30S13.9 59.167 30 59.167 59.167 46.1 59.167 30 46.1.833 30 .833z"
          fill="#FFF"
        />
        <path
          d="M30 .833C13.9.833.833 13.9.833 30S13.9 59.167 30 59.167 59.167 46.1 59.167 30 46.1.833 30 .833zm-5.833 39.375V19.792c0-1.196 1.37-1.896 2.333-1.167l13.62 10.208c.788.584.788 1.75 0 2.334L26.5 41.375c-.962.73-2.333.03-2.333-1.167z"
          fill="#D0A152"
        />
      </g>
    </svg>
  );
}

export default PlayButton;
