import { useRouter } from "next/router";
import { Flex, Box, Img, Skeleton, Center } from "@chakra-ui/react";

import Button from "../button";
import TopBand from "../topBand";
import ErrorToaster from "./errorToaster";

export default function Index({ errorMessage }) {
  const router = useRouter();
  return (
    <>
      <TopBand title="Error!" />
      <Center mt="10" flexDirection="column">
        <Flex width={{ base: "90%", "2xl": "1350px" }}>
          <ErrorToaster errorMessage={errorMessage} />
        </Flex>
        <Flex width="40%" margin="auto" mt="5%">
          <Center
            width="100%"
            height="80%"
            alignItems="center"
            justifyContent="center"
          >
            <Box width="80%" alignSelf="center" justifyContent="center">
              <Img
                h="100px"
                _hover={{
                  cursor: "pointer",
                }}
                margin="auto"
                fallback={<Skeleton w="100%" />}
                src={`${process.env.NEXT_PUBLIC_NEXTJS_URL}/empty_cart.png`}
              />
              <Flex
                my="10"
                width="100%"
                alignItems="center"
                flexDirection="column"
                justifyContent="center"
              >
                <Button
                  px="20.5px"
                  title="Return to shop"
                  backgroundColor="maroon"
                  width={{ base: "175px", md: null }}
                  handleClick={() => router.push("/shop")}
                />
              </Flex>
            </Box>
          </Center>
        </Flex>
      </Center>
    </>
  );
}
